@import "~bootstrap/scss/bootstrap";

:root {
    --astro-font-family: "comics-sans";
    --astro-body-font-family: "zohoPuvi";
    --astro-bg: #f8f4fc;
    --astro-nav-bg: #ff4e8f;
    --astro-nav-textColor: white;
    --astro-textColor: #370013;
    --astro-bg-subColor: #fbebf5
}

html {
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-size: 1em;
    font-family: var(--astro-font-family);
}

body {
    font-family: var(--astro-font-family);
    overflow: hidden;
    background-color: var(--astro-bg);
}

.astroNav {
    background-color: var(--astro-nav-bg);
    padding: 0rem 2rem;
    position: sticky;
    top: 0;
    background-image: linear-gradient(to top left, #ff4e8f, #b71d79);

    .logoHolder {
        justify-content: center;
        align-items: center;

        .navbar-brand {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            background-image: linear-gradient(to bottom right, #b91f79, #ff559e);
            box-shadow: 0 3px 6px #ff107f, 0 3px 6px #130705;
        }
    }

    .logoTextHolder {
        width: 70%;
        align-items: center;
        padding-left: 1rem;
        color: white;
        font-weight: 900;
        font-size: 2.75rem;
        //font-family: 'pacifico'
    }

    a {
        color: var(--astro-nav-textColor);
        margin: 0px;
    }

    img {
        width: 3rem;
    }

    ul {
        li.nav-item {
            display: inline-flex;
            flex-wrap: wrap;
            flex: 1;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-size: 1.15rem;
        }

        li.active {
            border: 1px solid white;
            background: white;
            border-radius: 18px;
            color: #ff4e8f;

            a {
                color: #ff015e !important;
                font-weight: 900;


            }
        }

        .nav-link:hover {
            color: white;
            font-size: 1.25rem;
        }

    }
}

.bodyContent {
    overflow: auto;
    background-color: var(--astro-bg);
    font-family: "zohoPuvi";
}

.astroContainer {
    padding: 1rem 2rem;
}



.homeContainer {
    //  background: url("./../assests/zodiac6.webp");
    margin: 0px;
    padding: 2rem;
    //  background-repeat: no-repeat;
    //  background-size: contain;
    background-color: #001530;

    .homeImgContainer {
        width: 30%;
        padding-right: 2rem
    }

    .homeTextHolder {
        width: 70%;

        .textContent {
            display: flex;
            align-items: center;
            color: white;
            font-size: 1.75rem;
            margin: 0.5rem 0px;

            .bullet {
                width: 0.75rem;
                height: 0.75rem;
                background: white;
                border-radius: 100%;
            }

            .textHolder {
                padding-left: 1rem;
                width: 100%;
            }
        }
    }
}

.homeContainer.pink {
    background-image: linear-gradient(#f2a2be, #f7f4fb);
}

.aboutContainer {
    padding: 1rem 2rem;

    .imageHolder {
        img {
            width: 7.5rem;
        }
    }

    .aboutTextContainer {
        padding: 0px 2.5rem;
        padding-right: 0px;

        .header {
            font-size: 2rem;
            font-weight: bold;
            color: var(--astro-textColor);
        }

        .textContainer {
            text-align: justify;
            //background-image: linear-gradient(to top left, #033c7a, #001530);
            background-image: linear-gradient(to top left, #366f34, #287748);
            color: white;
            padding: 2rem;
            font-size: 1.25rem;
            border-radius: 24px;
            margin-top: 1rem;
            height: 100%;
        }

        .sign {
            font-size: 3rem;
            text-align: right;
            font-family: darling;
            padding: 0rem 1rem;
        }

    }
}

.serviceContainer {
    .headerContent {
        color: var(--astro-textColor);
        text-align: center;

        .header {
            font-weight: bold;
        }
    }

    .highlightDiv {
        align-items: center;
        justify-content: center;
        background-color: #a6dcbe;
        color: var(--astro-textColor);
        padding: 1rem 2rem ;
        width: 100%;
        margin: 1rem 0px;
        font-size: 1.25rem;
    font-weight: bold;
    }

    .listOfservice {
        padding: 1rem 3rem;
        margin: 0px;

        .service {
            &:hover .contentHolder {
                box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(255 255 255 / 23%);
            }

            padding: 0.5rem;
            cursor: pointer;

            .contentHolder {
                background: white;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .imgService img {
                width: 2rem;
            }

            .header {
                font-weight: 900;
                text-align: center;
                background: #e1529e;
                padding: 0.5rem;
                font-size: 1.5rem;
                color: white;
                border-radius: 4px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .subHeader {
                color: #ffffff;
                font-size: 1rem;
                height: 100%;
                background: #2a7748;
                font-weight: bold;
                padding: 0.5rem;
                border-radius: 4px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
            }
        }
    }
}

.socialImg {
    margin: 0.5rem;
}

.socialImg:first-child {
    margin-left: 0px;
}

.socialImg img {
    width: 2rem;
}

.contactContainer {
    background-color: var(--astro-bg-subColor);
    padding: 2rem;
    color: var(--astro-textColor);
    margin-bottom: 0px;

    .header {
        font-weight: bold;
        font-size: 1.1rem;
    }
}

.privacyContainerHolder {
    margin-top: 0.5rem;
    background: #ffa9dd !important;

    .privacyContainer {
        text-align: justify;
        font-size: 1.1rem;
        font-weight: bold;
        display: flex;
        align-items: center;

        img {
            width: 2rem;
            margin: 0px 1rem;
        }
    }
}

.headerText {
    font-size: 2rem !important;
    font-family: var(--astro-font-family);
    justify-content: center;
}

.headerWithHighLight {
    background: #ca2a7f;
    color: white !important;
    padding: 0.5rem 2rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: var(--astro-font-family);
    display: flex;
    justify-content: center;
}